import { loadStripe } from '@stripe/stripe-js';
import {
  addArrayToDictionary, add, subtract, isCurrencyAmountTooLow, getFullName, getEnvVar,
} from '@utils';
import {
  confirmNewFeeStructure,
} from '@care-provider/stores/modules/provider-user.service';
import {
  fetchPayees,
  addCardPaymentMethod,
  fetchPaymentMethods,
  fetchPaymentRequests,
  fetchPayMeRequest,
  addPaymentRequest,
  updatePaymentRequest,
  cancelPaymentRequest,
  initiatePayment,
  addBankAccountPaymentMethod,
  addProvider,
  removePaymentMethod,
  checkPaymentStatus,
  getPaymentCouponsToApply,
  doPaymentMathAndGetCommissions,
  checkNaviaBalance,
  submitCheckRequest,
  canRemovePaymentMethod,
  declinePayMeRequest,
  fetchUserPayMeRequests,
  acceptEnrollmentAutopay,
} from './payments.service';

const completedStatuses = [
  'failed',
  'completed',
];

const pendingStatuses = [
  'created',
  'payin_initiating',
  'payin_bank_transactions_inititated',
  'payin_all_transactions_inititated',
  'funds_received',
  'payout_initiated',
];

export default {
  namespaced: true,

  state: {
    payees: {},
    paymentMethods: {},
    paymentRequests: {},
    currentPayment: {
      paymentRequestMethods: [],
      paymentRequestItems: [],
    },
    payMeRequest: {},
    userPayMeRequests: [],
    coupons: {},
    navia: {},
    isInitialized: false,
  },

  getters: {
    scheduledPaymentRequest(state) {
      return Object.values(state.paymentRequests)
        .filter((request) => request.status === 'scheduled')
        .sort((a, b) => Date.parse(a.scheduledAt) - Date.parse(b.scheduledAt))[0];
    },
    paymentMethodTypes() {
      return {
        fsaCard: 'DCFSA Debit Card',
        stripeCard: 'Credit / Debit Card',
        bankAccount: 'Direct debit from a bank account',
      };
    },

    pendingPaymentRequestIds(state) {
      return Object.values(state.paymentRequests)
        .filter((request) => pendingStatuses.includes(request.status))
        .map(({ id }) => id);
    },

    schedulePaymentRequestIds(state) {
      return Object.values(state.paymentRequests)
        .filter((request) => request.status === 'scheduled')
        .map(({ id }) => id);
    },

    completedPaymentRequestIds(state) {
      return Object.values(state.paymentRequests)
        .filter((request) => completedStatuses.includes(request.status))
        .map(({ id }) => id);
    },

    nonScheduledPaymentRequestIds(state) {
      return Object.values(state.paymentRequests)
        .filter((request) => !['scheduled', 'canceled', 'requested'].includes(request.status))
        .map(({ id }) => id);
    },

    allPaymentRequestIds(state) {
      return Object.values(state.paymentRequests)
        .map(({ id }) => id);
    },

    currentPayment(state) {
      return state.currentPayment;
    },

    currentPaymentHasFsaMethod(state) {
      return state.currentPayment.paymentRequestMethods.some(
        (method) => state.paymentMethods[method.paymentMethodId].fsa,
      );
    },

    currentPaymentHasBankAccount(state) {
      return state.currentPayment.paymentRequestMethods.some(
        (method) => state.paymentMethods[method.paymentMethodId].paymentMethodType.includes('BankAccount'),
      );
    },

    currentPaymentTotalFees(state) {
      const paymentMethods = state.currentPayment.paymentRequestMethods || [];
      return paymentMethods.length
        ? state.currentPayment.paymentRequestMethods
          .reduce((sum, method) => add(sum, method.commissionAmount), 0)
        : 0;
    },

    currentPaymentDiscounts(state) {
      const uniqDiscountsFromPaymentMethods = [];

      state.currentPayment.paymentRequestMethods.forEach(({ couponsApplied }) => {
        if (couponsApplied) {
          couponsApplied.forEach(({ code, discountAmount }) => {
            const coupon = uniqDiscountsFromPaymentMethods.find((d) => d.code === code);
            if (coupon) {
              coupon.amount = add(coupon.amount, discountAmount);
            } else if (discountAmount) {
              uniqDiscountsFromPaymentMethods.push({ code, amount: discountAmount });
            }
          });
        }
      });

      return uniqDiscountsFromPaymentMethods;
    },

    currentPaymentTotalDiscount(state) {
      return state.currentPayment.paymentRequestMethods
        .reduce((sum, method) => add(sum, method.discountAmount), 0);
    },

    currentPaymentTotalFeeDiscount(state) {
      return state.currentPayment.paymentRequestMethods.reduce((sum, method) => {
        const feeDiscount = (method.couponsApplied || []).reduce((methodSum, coupon) => {
          const isFeeDiscount = ['ACHFREE', 'WELCOME2020'].includes(coupon.code);
          return isFeeDiscount ? add(methodSum, coupon.discountAmount) : methodSum;
        }, 0);
        return add(sum, feeDiscount);
      }, 0);
    },

    currentPaymentFeeAfterDiscount(state, getters) {
      const { currentPaymentTotalFees, currentPaymentTotalFeeDiscount } = getters;
      return subtract(currentPaymentTotalFees, currentPaymentTotalFeeDiscount);
    },

    currentPaymentTotalInPaymentMethods(state) {
      return (state.currentPayment.paymentRequestMethods || [])
        .reduce((sum, method) => add(sum, method?.totalAmount || 0), 0);
    },

    currentPaymentTotalRemainingWithFee(state, getters) {
      if (state.currentPayment.totalToBeCharged) {
        return subtract(
          state.currentPayment.totalToBeCharged,
          getters.currentPaymentTotalInPaymentMethods,
        );
      }
      return 0;
    },

    currentPaymentProviderName(state) {
      const { providerId, newProvider } = state.currentPayment;
      const provider = providerId && state.payees[providerId];
      return getFullName(provider || newProvider, provider?.email || 'Your contact');
    },

    currentPaymentFacility(state, g, rootState) {
      const { facilityId } = state.currentPayment;
      return facilityId && rootState.facilities.storedFacilities[facilityId];
    },

    currentPaymentFacilityName(state, getters) {
      const { providerId, newProvider } = state.currentPayment;
      const { companyName } = newProvider || {};
      const { currentPaymentFacility, currentPaymentProviderName } = getters;
      const payee = (providerId && state.payees[providerId]);
      const facilityName = currentPaymentFacility?.name || payee?.companyName;

      return facilityName || companyName || currentPaymentProviderName || 'Facility';
    },

    currentPaymentIsSomeAmountTooLow(state) {
      const isAmountTooLow = isCurrencyAmountTooLow(state.currentPayment.amount);
      const isSomeMethodAmountTooLow = state.currentPayment.paymentRequestMethods
        .map((m) => m.totalAmount).some(isCurrencyAmountTooLow);

      return isAmountTooLow || isSomeMethodAmountTooLow;
    },

    currentPaymentHasNoLongerActiveCard(state) {
      if (!state.currentPayment.error || !state.currentPayment.error.relatedObj) {
        return false;
      }

      const { error } = state.currentPayment;

      if (error.relatedObj.class !== 'PaymentMethod') {
        return false;
      }

      const isErrorRelatedToCurrentPaymentMethods = state.currentPayment.paymentRequestMethods
        .map((m) => m.paymentMethodId)
        .includes(error.relatedObj.id);

      const isNoLongerActiveError = (error.message || '').toLowerCase().includes('no longer active');

      return isErrorRelatedToCurrentPaymentMethods && isNoLongerActiveError;
    },

    paymentMethodsAsArray(state) {
      return Object.values(state.paymentMethods)
        .filter((method) => method !== undefined && !method.deleted);
    },

    payMeRequest(state) {
      return state.payMeRequest;
    },

    userPayMeRequests(state) {
      return state.userPayMeRequests;
    },

    payeeIds(state) {
      return Object.keys(state.payees);
    },

    payeesAsArray(state) {
      return Object.values(state.payees);
    },

    couponCodes(state) {
      return Object.keys(state.coupons);
    },

    hasNaviaCard(state, getters) {
      return getters.paymentMethodsAsArray.some((m) => m.navia);
    },

    naviaCard(s, getters) {
      return getters.paymentMethodsAsArray.filter((m) => m.navia)[0];
    },

    naviaCardId(s, getters) {
      return getters.paymentMethodsAsArray.filter((m) => m.navia)[0]?.id;
    },
  },

  mutations: {
    addPayees: (state, payees) => {
      state.payees = { ...addArrayToDictionary(payees, state.payees) };
    },

    addPaymentMethods: (state, methods) => {
      state.paymentMethods = { ...addArrayToDictionary(methods, state.paymentMethods) };
    },

    removePaymentMethod: (state, id) => {
      state.paymentMethods = {
        ...state.paymentMethods,
        [id]: { ...state.paymentMethods[id], deleted: true },
      };
    },

    resetPaymentRequests: (state) => {
      state.paymentRequests = {};
    },

    addPaymentRequests: (state, requests) => {
      state.paymentRequests = { ...addArrayToDictionary(requests, state.paymentRequests) };
    },

    updateCurrentPayment: (state, newPaymentDetails) => {
      state.currentPayment = {
        ...state.currentPayment,
        ...newPaymentDetails,
      };
    },

    setCurrentPayment: (state, initData) => {
      state.currentPayment = {
        providerId: undefined,
        facilityId: undefined,
        newProvider: undefined,
        amount: undefined,
        category: undefined,
        paymentRequestMethods: [],
        ...initData,
      };
    },

    updateCurrentPaymentMethods: (state, paymentRequestMethods) => {
      state.currentPayment = {
        ...state.currentPayment,
        paymentRequestMethods,
      };
    },

    updatePaymentRequest: (state, request) => {
      state.paymentRequests[request.id] = request;
    },

    updatePaymentRequestStatus: (state, { paymentRequestId, status }) => {
      const request = { ...state.paymentRequests[paymentRequestId], status };
      state.paymentRequests[paymentRequestId] = request;
    },

    setCoupons: (state, coupons) => {
      state.coupons = addArrayToDictionary(coupons, undefined, 'code');
    },

    storeInitialized: (state) => {
      state.isInitialized = true;
    },

    setNavia: (state, payload) => {
      state.navia = payload;
    },

    setPayMeRequest: (state, payMeRequest) => {
      state.payMeRequest = payMeRequest;
    },

    setUserPayMeRequests: (state, userPayMeRequests) => {
      state.userPayMeRequests = userPayMeRequests;
    },
  },

  actions: {
    // setup user
    async fetchPaymentMethods({ commit, state, dispatch }) {
      try {
        const isPaymentMethodsEmpty = Object.keys(state.paymentMethods).length === 0;
        if (isPaymentMethodsEmpty) {
          const paymentMethods = await fetchPaymentMethods();
          commit('addPaymentMethods', paymentMethods);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch payment methods', error }, { root: true });
        throw new Error();
      }
    },

    // setup payments store
    async initializePaymentsStore({ dispatch, commit, state }) {
      if (!state.isInitialized) {
        return new Promise((resolve, reject) => {
          const promises = [
            dispatch('fetchPayees'),
            dispatch('fetchPaymentRequests'),
          ];

          Promise.all(promises)
            .then(() => {
              commit('storeInitialized');
              resolve();
            })
            .catch(() => {
              reject(new Error('Could not initialize payments module'));
            });
        });
      }
      return undefined;
    },

    async fetchPayees({ commit, dispatch }) {
      try {
        const payees = await fetchPayees();
        commit('addPayees', payees);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch payees', error }, { root: true });
      }
    },

    async fetchPaymentRequests({ commit, dispatch }) {
      try {
        const paymentRequests = await fetchPaymentRequests();
        commit('resetPaymentRequests');
        commit('addPaymentRequests', paymentRequests);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch payment history', error }, { root: true });
      }
    },

    // setup payment
    async setCurrentPayment({ dispatch, commit }, initData) {
      try {
        commit('setCurrentPayment', initData);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not initialize sending payment', error }, { root: true });
      }
    },

    async updateCurrentPayment({ commit, dispatch }, newPaymentDetails) {
      try {
        commit('updateCurrentPayment', newPaymentDetails);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update payment details', error }, { root: true });
      }
    },

    async getPaymentCoupons({ commit, dispatch }) {
      try {
        const coupons = await getPaymentCouponsToApply();
        commit('setCoupons', coupons);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not get coupons', error }, { root: true });
        throw new Error(error);
      }
    },

    // manage the amounts
    async setTotalAmount({ commit, dispatch }, amount) {
      try {
        await commit('updateCurrentPayment', {
          amount,
        });

        await dispatch('doPaymentMathAndGetCommissions');
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update total amount', error }, { root: true });
      }
    },

    async updateMethodInCurrentPayment({ commit, state, dispatch }, method) {
      if (!method) {
        return;
      }

      // eslint-disable-next-line
      let { paymentRequestMethods, totalToBeCharged } = state.currentPayment;
      const methodToUpdateIndex = paymentRequestMethods.findIndex(
        (existingMethod) => existingMethod.paymentMethodId === method.paymentMethodId,
      );

      if (methodToUpdateIndex < 0) {
        paymentRequestMethods.push(method);
      } else {
        paymentRequestMethods[methodToUpdateIndex].totalAmount = method.totalAmount;
      }

      if (paymentRequestMethods.length === 2 && !!method.totalAmount) {
        const secondMethodIndex = methodToUpdateIndex < 0 ? 0 : 1 - methodToUpdateIndex;
        const secondPayment = paymentRequestMethods[secondMethodIndex];
        if (secondPayment) {
          const { paymentMethodId } = secondPayment;
          paymentRequestMethods[secondMethodIndex] = { paymentMethodId };
          // if the user edit split payment then choose whole amount,
          // we need to remove the other payment method
          if (method.totalAmount === totalToBeCharged) {
            paymentRequestMethods = paymentRequestMethods
              .filter((paymentMethod) => paymentMethod.paymentMethodId !== paymentMethodId);
          }
        }
      }
      await commit('updateCurrentPaymentMethods', paymentRequestMethods);
      await dispatch('doPaymentMathAndGetCommissions');
    },

    async removeMethodFromCurrentPayment({ commit, state, dispatch }, methodId) {
      if (!methodId) {
        return;
      }
      const { paymentRequestMethods } = state.currentPayment;
      const filteredPaymentRequestMethods = paymentRequestMethods
        .filter((method) => method.paymentMethodId !== methodId);

      await commit('updateCurrentPaymentMethods', filteredPaymentRequestMethods);
      await dispatch('doPaymentMathAndGetCommissions');
    },

    async doPaymentMathAndGetCommissions({
      commit, dispatch, state, getters, rootState,
    }) {
      try {
        const { amount, paymentRequestMethods, providerId } = state.currentPayment;
        const params = {
          amount,
          paymentRequestMethods,
          coupons: getters.couponCodes,
          seatId: rootState.route.params.seatId,
          centerId: rootState.legup.centerId,
          providerId,
        };
        const data = await doPaymentMathAndGetCommissions(params);
        if (!state.currentPayment.paymentRequestMethods) {
          commit('updateCurrentPayment', data);
          return;
        }

        const updatedPaymentRequestMethods = state.currentPayment.paymentRequestMethods
          .map((method) => {
            let methodFromResponse = {};
            if (data.paymentRequestMethods) {
              methodFromResponse = data.paymentRequestMethods
                .find(({ paymentMethodId }) => paymentMethodId === method.paymentMethodId);
            }
            return {
              ...method,
              ...methodFromResponse,
            };
          });
        commit('updateCurrentPayment', {
          ...data,
          paymentRequestMethods: updatedPaymentRequestMethods,
        });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not calculate payment commissions', error }, { root: true });
        throw new Error(error);
      }
    },

    // start a payment request
    async addPaymentProvider({ dispatch }, newProvider) {
      try {
        const provider = await addProvider(newProvider);
        return provider.id;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not add a provider', error }, { root: true });
        throw new Error();
      }
    },

    async addPaymentRequest({ commit, dispatch, getters }, currentPayment) {
      try {
        const requestedPayment = await addPaymentRequest({
          ...currentPayment,
          paymentCoupons: getters.couponCodes,
        });
        await commit('updateCurrentPayment', {
          status: requestedPayment.status,
          id: requestedPayment.id,
        });
        commit('addPaymentRequests', [requestedPayment]);
        return requestedPayment.id;
      } catch (error) {
        const text = error.response.data.message || 'Could not send a payment request';
        dispatch('notifications/addToastError', { text, error }, { root: true });
        throw new Error();
      }
    },

    // eslint-disable-next-line max-len
    async updatePaymentRequest({ commit, dispatch, getters }, { id, applyChangesToCollection, ...data }) {
      try {
        const params = { ...data, paymentCoupons: getters.couponCodes, applyChangesToCollection };
        const updatedPayment = await updatePaymentRequest(params, id);
        commit('updatePaymentRequest', updatedPayment);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not update a payment request', error }, { root: true });
        throw new Error();
      }
    },

    async cancelPaymentRequest({ commit, dispatch }, { id }) {
      try {
        const updatedPayment = await cancelPaymentRequest(id);
        commit('updatePaymentRequest', updatedPayment);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not cancel a payment request', error }, { root: true });
        throw new Error();
      }
    },

    // finish payment request
    async initiatePayment({ dispatch, commit }, paymentRequestId) {
      try {
        await initiatePayment(paymentRequestId);
        commit('updatePaymentRequestStatus', { paymentRequestId, status: 'payin_initiating' });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not initiate payment', error }, { root: true });
        if (error.response?.data) {
          commit('updateCurrentPayment', { error: { message: error.response.data } });
        }
        throw new Error();
      }
    },

    async checkPaymentStatus({ commit, dispatch, state }, paymentRequestId) {
      try {
        const currentStatus = state.paymentRequests[paymentRequestId].status;
        const response = await checkPaymentStatus(paymentRequestId);
        if (currentStatus !== response.status) {
          commit('updatePaymentRequestStatus', { paymentRequestId, status: response.status });
        }
        return response;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not check payment status', error }, { root: true });
        throw new Error();
      }
    },

    // manage payment methods
    async addCard({
      dispatch, commit, getters,
    }, { cardType, cardData, userId }) {
      let stripePaymentMethod;

      // NAVIA FSA: CARD CHECK

      // stripe
      try {
        const stripe = await dispatch('loadStripe');
        const { nameOnCard, ...stripeData } = cardData;
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: stripeData.cardNumber,
          billing_details: {
            name: nameOnCard,
          },
        });
        stripePaymentMethod = paymentMethod;
        if (error) {
          throw new Error(error);
        }
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not add a card, please check card details', error }, { root: true });
        throw new Error();
      }

      // our db
      try {
        const isFsa = cardType === getters.paymentMethodTypes.fsaCard;
        const addedMethod = await addCardPaymentMethod(stripePaymentMethod.id, isFsa, userId);
        commit('addPaymentMethods', [addedMethod]);
        return addedMethod.id;
      } catch (error) {
        dispatch('notifications/addToastError', { text: error.response?.data?.error || 'Could not add a card, please check card details', error }, { root: true });
        throw new Error();
      }
    },

    async loadStripe({ dispatch }) {
      try {
        if (window.stripe !== undefined) {
          return window.stripe;
        }
        const stripeKey = getEnvVar('STRIPE_PUBLISHABLE_KEY') || 'pk_test_Z6CKyvULf8ZrRYUMCCs70tXu00SWlreOO6';
        const stripe = await loadStripe(stripeKey);
        window.stripe = stripe;
        return window.stripe;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not load stripe', error }, { root: true });
        throw new Error();
      }
    },

    async addBankAccount({ commit, dispatch }, { publicToken, accountId, userId }) {
      try {
        const addedMethod = await addBankAccountPaymentMethod(publicToken, accountId, userId);
        commit('addPaymentMethods', [addedMethod]);
        return addedMethod.id;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not add a new bank account', error }, { root: true });
        throw new Error();
      }
    },

    async removePaymentMethod({ commit, dispatch }, id) {
      try {
        await removePaymentMethod(id);
        commit('removePaymentMethod', id);
      } catch (error) {
        dispatch('notifications/addToastError', { text: `Could not remove payment method: ${id}`, error }, { root: true });
        throw new Error();
      }
    },

    async checkNaviaBalance({ commit, getters }) {
      commit('setNavia', {});
      if (!getters.hasNaviaCard) {
        return;
      }

      try {
        const response = await checkNaviaBalance();
        const {
          nextContributionDate, availableBalance, paymentMethodId, gracePeriodDate,
        } = response;

        commit('setNavia', {
          nextContributionDate,
          balance: availableBalance,
          cardId: paymentMethodId,
          expirationDate: gracePeriodDate,
        });
      } catch (e) {
        const { errorMessage, error, paymentMethodId } = e.response?.data || {};

        commit('setNavia', {
          errorMessage,
          error: error || true,
          cardId: paymentMethodId,
        });
      }
    },

    async submitCheckRequest(_, data) {
      await submitCheckRequest(data);
    },

    async confirmNewFeeStructure({ dispatch }, { providerId }) {
      try {
        const response = await confirmNewFeeStructure(providerId);
        return response;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not optin', error }, { root: true });
        return { error };
      }
    },
    async canRemovePaymentMethod({ dispatch }, { id }) {
      try {
        const response = await canRemovePaymentMethod(id);
        return response.data.can_remove;
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not check payment method status', error }, { root: true });
        throw new Error();
      }
    },

    async fetchPayMeRequest({ commit, dispatch }, { id, facilityId }) {
      try {
        const payMeRequest = await fetchPayMeRequest({ id, facilityId });
        commit('setPayMeRequest', payMeRequest);
        commit('setCurrentPayment', payMeRequest);
        // set user for guest user
        if (payMeRequest.user) {
          await dispatch('user/setCurrentUser', payMeRequest.user, { root: true });
        }
      } catch (error) {
        commit('setPayMeRequest', null);
        dispatch('notifications/addToastError', { text: 'Could not fetch pay me request', error }, { root: true });
      }
    },

    async declinePayMeRequest({ dispatch }, params) {
      try {
        await declinePayMeRequest(params);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not decline pay me request', error }, { root: true });
      }
    },

    async acceptEnrollmentAutopay({ dispatch }, currentPayment) {
      try {
        await acceptEnrollmentAutopay({
          ...currentPayment,
        });
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not accept autopay', error }, { root: true });
        throw new Error();
      }
    },

    async fetchUserPayMeRequests({ commit, dispatch }) {
      try {
        const payMeRequests = await fetchUserPayMeRequests();
        commit('setUserPayMeRequests', payMeRequests);
      } catch (error) {
        dispatch('notifications/addToastError', { text: 'Could not fetch users pay me requests', error }, { root: true });
      }
    },
  },
};
